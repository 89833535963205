import { TMApi } from "../api/calls"

const url = '/api/bookings'

class BookingService {

    verifyBooking(orderId, ticketsCount) {
        return TMApi.post(`/api/booking/verify`, {
            orderId,
            verified_tickets_count: ticketsCount
        })
    }

    sendBookingMail(orderId, ccMail) {
        return TMApi.post('/api/send/orderMail', {
            order_id: orderId,
            ccMail
        })
    }

    getAllBookings(event_status = ['ongoing', 'soldout', 'closed', 'postponed']) {
        return TMApi.get(url, {
            params: {
                event_status
            }
        })
    }

    updateBooking(data, id) {
        return TMApi.put(`${url}/${id}`, data)
    }

    deleteBooking(id) {
        return TMApi.delete(`${url}/${id}`)
    }

    getFailedJobs() {
        return TMApi.get('/api/jobs/failed')
    }

    retryFailedJobs(id) {
        return TMApi.post('/api/jobs/failed', { id })
    }

    generateETicket(id) {
        return TMApi.post(`${url}/generateticket/${id}`)
    }
}

export default new BookingService()